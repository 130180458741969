import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "../../../../utils/axios";
import { FormEvent, useEffect, useState } from "react";
import { dbCompany } from "../../../../api/parnerus/types/company";
import { useBitrixContext } from "../../../../api/bitrix/context/BitrixContext";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { dbContact } from "../../../../api/parnerus/types/contact";
import axiosInstance from "../../../../utils/axios";

interface Props extends DialogProps {
  onClose: VoidFunction;
  onAddCompany?: (company: dbCompany) => void;
}

interface FormProps {
  TITLE: string;
  INDUSTRY: string;
  UF_STATUS: string;
  UF_IRS_STATUS: string;
  UF_STATE_OF_INCORPORATION: string;
  UF_TYPE_OF_SERVICE: string[];
  UF_INCORPORATION_DATE: string;
  WEB: string;
  COMMENTS: string;
  primaryContactId: {
    id: string;
    label: string;
  } | null;
}

const formDefaults: FormProps = {
  TITLE: "",
  INDUSTRY: "",
  UF_STATUS: "",
  UF_IRS_STATUS: "",
  UF_STATE_OF_INCORPORATION: "",
  UF_TYPE_OF_SERVICE: [],
  UF_INCORPORATION_DATE: "",
  WEB: "",
  COMMENTS: "",
  primaryContactId: null,
};

export default function CreateCompanytModal({
  onClose,
  open,
  onAddCompany,
}: Props) {
  const { account } = useAuthContext();
  const { listFields, userfieldList } = useBitrixContext();
  const [formValues, setFormValues] = useState(formDefaults);
  const [onSubmit, setOnSubmit] = useState(false);

  // Company filtering
  const [contactList, setContactList] = useState<dbContact[]>([]);
  const [contactSearchingTimer, setContactSearchingTimer] =
    useState<NodeJS.Timeout | null>(null);
  const [isSearchingContact, setIsSearchingContact] = useState(false);

  const handleContactSearchInput = (
    event: { target: { value: string } },
    isFirstSearch: boolean
  ) => {
    setIsSearchingContact(true);
    const searchingValue = event.target.value.toLowerCase();
    if (contactSearchingTimer) clearTimeout(contactSearchingTimer);

    const newContactTimer = setTimeout(async () => {
      if (isFirstSearch || searchingValue.length > 2) {
        const searchingResults = await axiosInstance.get(
          `/api/contact/list-by-name?NAME=${searchingValue}&accountId=${account?.accountId}`
        );
        setContactList(searchingResults.data.results);
        setIsSearchingContact(false);
      } else {
        setContactList([]);
        setIsSearchingContact(false);
      }
    }, 1500);

    setContactSearchingTimer(newContactTimer);
  };

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmit(true);
    const response = await axios.post("/api/company/insert", {
      ...formValues,
      accountId: account?.accountId,
      primaryContactId: formValues.primaryContactId?.id,
    });

    if (onAddCompany) {
      onAddCompany(response.data.company);
    }
    setFormValues(formDefaults);
    onClose();
    setOnSubmit(false);
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (open) {
      handleContactSearchInput({ target: { value: "" } }, true);
    }
  }, [open]);

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle> Crear empresa </DialogTitle>

        <form onSubmit={submitForm}>
          <DialogContent sx={{ overflow: "unset" }}>
            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Información general
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="TITLE"
                value={formValues.TITLE}
                onChange={handleInputChange}
                fullWidth
                label="Nombre de la Empresa"
                required
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Sector de actividad"
                fullWidth
                name="INDUSTRY"
                value={formValues.INDUSTRY}
                onChange={handleInputChange}
              >
                {listFields
                  .find((u) => u.FIELD_NAME === "INDUSTRY")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.STATUS_ID} value={value.STATUS_ID}>
                      {value.NAME}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                select
                label="Status"
                fullWidth
                name="UF_STATUS"
                value={formValues.UF_STATUS}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683977873")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="IRS Status"
                fullWidth
                name="UF_IRS_STATUS"
                value={formValues.UF_IRS_STATUS}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683978024")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                select
                label="State of Incorporation"
                fullWidth
                name="UF_STATE_OF_INCORPORATION"
                value={formValues.UF_STATE_OF_INCORPORATION}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683982286")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <Autocomplete
                fullWidth
                loading={isSearchingContact}
                value={formValues.primaryContactId}
                disablePortal
                options={contactList.map((c) => ({
                  label: `${c.NAME} ${c.LAST_NAME}`,
                  id: c.contactId as string,
                }))}
                onChange={(event, newValue) => {
                  setFormValues({
                    ...formValues,
                    primaryContactId: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contacto Principal"
                    onChange={(e) => {
                      handleContactSearchInput(e, false);
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Stack>

            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Detalles
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Type of service"
                fullWidth
                name="UF_TYPE_OF_SERVICE"
                value={formValues.UF_TYPE_OF_SERVICE}
                onChange={(event) =>
                  handleInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: [event.target.value],
                    },
                  })
                }
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684078719")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
              <DesktopDatePicker
                label="Incorporation date"
                value={
                  formValues.UF_INCORPORATION_DATE
                    ? new Date(formValues.UF_INCORPORATION_DATE)
                    : null
                }
                onChange={(newDate) => {
                  if (
                    newDate instanceof Date &&
                    !isNaN(newDate as unknown as number)
                  ) {
                    setFormValues({
                      ...formValues,
                      UF_INCORPORATION_DATE: newDate.toISOString(),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} margin="normal" />
                )}
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="WEB"
                value={formValues.WEB}
                onChange={handleInputChange}
                fullWidth
                label="Sitio web"
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                fullWidth
                label="Comentario"
                multiline
                rows={4}
                name="COMMENTS"
                value={formValues.COMMENTS}
                onChange={handleInputChange}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              color="inherit"
              variant="outlined"
              onClick={onClose}
              disabled={onSubmit}
            >
              Cancelar
            </Button>

            <Button variant="contained" type="submit" disabled={onSubmit}>
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
