import axios from "./helpers/axios";
import { Contact } from "./types/contact";

export const getContactById = (contactId: string): Promise<Contact> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.contact.get?ID=${contactId}`
      )
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const getContactsByIds = (
  contactIds: string[],
  accountId: string
): Promise<Contact[]> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.contact.list`, {
        FILTER: {
          ID: contactIds,
          UF_CRM_PARNERUS_ACCOUNT_ID: accountId,
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const getContactsByNameAndLastname = (
  textFilter: string,
  accountId: string
): Promise<Contact[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.contact.list?FILTER[%NAME%]=${textFilter}&FILTER[%LAST_NAME%]=${textFilter}&FILTER[UF_CRM_PARNERUS_ACCOUNT_ID]=${accountId}&ORDER[ID]=DESC`
      )
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const createContact = (
  contact: Contact,
  companyId: string | undefined
): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.contact.add`, {
        FIELDS: {
          ...contact,
          COMPANY_ID: companyId,
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const updateContact = (contact: Partial<Contact>): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.contact.update`, {
        ID: contact.ID,
        FIELDS: {
          ...contact,
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};
