/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "a5a83464e8654f8caf7431fb7fe34610",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a5a83464e8654f8caf7431fb7fe34610",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "InAppMessaging": {
            "AWSPinpoint": {
                "appId": "a5a83464e8654f8caf7431fb7fe34610",
                "region": "us-east-1"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://iulkjjyjavemzmkw5xjpohsoym.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-cv222riinrdbzfv5oekqbabfmu",
    "aws_cognito_identity_pool_id": "us-east-1:cc326178-76c5-4aec-a8c6-b7ec109390be",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Kly93yrrr",
    "aws_user_pools_web_client_id": "4cd42fso915j028deukun1ng1v",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
