/* eslint-disable indent */
import { useState, useRef, FormEvent, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Drawer,
  Divider,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
// components
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";
//
import OrderCreateToolbar from "./OrderCreateToolbar";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import OrderAttachments from "./OrderCreateAttachments";
import CreateContactModal from "../../pages/CRM/Contacts/CreateContact/CreateContactModal";
import OrderFooter from "./OrderCreateFooter";
import { useSharedContext } from "../SharedComponentsContext";
import CreateCompanytModal from "../../pages/CRM/Companies/CreateCompany/CreateCompanyModal";
import axiosInstance from "../../utils/axios";
import { dbContact } from "../../api/parnerus/types/contact";
import { dbCompany } from "../../api/parnerus/types/company";
import { BitrixFile } from "../../api/bitrix/types/deal";
import { fileToBase64 } from "../../api/parnerus/helpers/files";
import { useAuthContext } from "../../auth/useAuthContext";
import htmlParser from "html-react-parser";

// ----------------------------------------------------------------------

const StyledLabel = styled("span")(({ theme }) => ({
  ...theme.typography.caption,
  width: 120,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export type OrderProduct = {
  id: string;
  product: string;
  quantity: number;
  price: number;
  total: number;
};

interface FormProps {
  company: {
    id: string;
    label: string;
  } | null;
  contact: {
    id: string;
    label: string;
  } | null;
  documents: (File | string)[];
  products: OrderProduct[];
}

const formDefaults: FormProps = {
  company: null,
  contact: null,
  documents: [],
  products: [
    {
      id: "",
      product: "",
      quantity: 1,
      price: 0,
      total: 0,
    },
  ],
};

export default function OrderDetails({ isOpen, onClose }: Props) {
  const [formValues, setFormValues] = useState(formDefaults);
  const [onSubmit, setOnSubmit] = useState(false);

  const { account, user } = useAuthContext();
  const { closeOrderCreateSlider, openPaymentModal } = useSharedContext();

  //Client Modals
  const [companyCreateOpen, setCompanyCreateOpen] = useState(false);
  const addCompany = (company: dbCompany) => {
    setCompanyList([...companyList, company]);
    setFormValues({
      ...formValues,
      company: {
        id: company.companyId,
        label: company.TITLE,
      },
    });
  };

  const [contactCreateOpen, setContactCreateOpen] = useState(false);
  const addContact = (contact: dbContact) => {
    setContactsList([...contactsList, contact]);
    setFormValues({
      ...formValues,
      contact: {
        id: contact.contactId as string,
        label: `${contact.NAME} ${contact.LAST_NAME}`,
      },
    });
  };

  // Company filtering
  const [companyList, setCompanyList] = useState<dbCompany[]>([]);
  const [companySearchingTimer, setCompanySearchingTimer] =
    useState<NodeJS.Timeout | null>(null);

  // Contact filtering
  const [contactsList, setContactsList] = useState<dbContact[]>([]);
  const [contactSearchingTimer, setContactSearchingTimer] =
    useState<NodeJS.Timeout | null>(null);

  const { productsList } = useBitrixContext();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [liked, setLiked] = useState(false);

  const [completed, setCompleted] = useState(true);

  const handleLiked = () => {
    setLiked(!liked);
  };

  const handleCompleted = () => {
    setCompleted(!completed);
  };

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  const [isSearchingCompany, setIsSearchingCompany] = useState(false);

  const handleCompanySearchInput = (
    event: { target: { value: string } },
    isFirstSearch: boolean
  ) => {
    setIsSearchingCompany(true);
    const searchingValue = event.target.value.toLowerCase();
    if (companySearchingTimer) clearTimeout(companySearchingTimer);

    const newContactTimer = setTimeout(async () => {
      if (isFirstSearch || searchingValue.length > 2) {
        setCompanyList(
          (
            await axiosInstance.get(
              `/api/company/list-by-title?TITLE=${searchingValue}&accountId=${account?.accountId}`
            )
          ).data.results
        );
        setIsSearchingCompany(false);
      } else {
        setCompanyList([]);
        setIsSearchingCompany(false);
      }
    }, 1500);

    setCompanySearchingTimer(newContactTimer);
  };

  const [isSearchingContact, setIsSearchingContact] = useState(false);

  const handleContactSearchInput = (
    event: { target: { value: string } },
    isFirstSearch: boolean
  ) => {
    setIsSearchingContact(true);
    const searchingValue = event.target.value.toLowerCase();
    if (contactSearchingTimer) clearTimeout(contactSearchingTimer);

    const newContactTimer = setTimeout(async () => {
      if (isFirstSearch || searchingValue.length > 2) {
        setContactsList(
          (
            await axiosInstance.get(
              `/api/contact/list-by-name?NAME=${searchingValue}&accountId=${account?.accountId}`
            )
          ).data.results
        );
        setIsSearchingContact(false);
      } else {
        setContactsList([]);
        setIsSearchingContact(false);
      }
    }, 1500);

    setContactSearchingTimer(newContactTimer);
  };

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formValues);
    setOnSubmit(true);

    const dealFiles: BitrixFile[] = [];
    for (const document of formValues.documents) {
      const fileData = (await fileToBase64(document as File)) as string;
      if (fileData) {
        dealFiles.push({
          fileData: [(document as File).name, fileData.split(";base64,")[1]],
        });
      }
    }

    openPaymentModal({
      contact: contactsList.find(
        (c) => c.contactId === formValues.contact?.id
      ) as dbContact,
      company: companyList.find((c) => c.companyId === formValues.company?.id),
      products: formValues.products,
      files: dealFiles,
      accountId: account?.accountId,
      productsList,
      mediadorId: user?.bitrixId,
    });
    setFormValues(formDefaults);
    onClose();
    setOnSubmit(false);
  };

  useEffect(() => {
    if (isOpen) {
      handleContactSearchInput({ target: { value: "" } }, true);
      handleCompanySearchInput({ target: { value: "" } }, true);
    }
  }, [isOpen]);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeOrderCreateSlider}
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: 1,
              sm: 900,
            },
          },
        }}
      >
        <OrderCreateToolbar
          fileInputRef={fileInputRef}
          liked={liked}
          completed={completed}
          onLike={handleLiked}
          onAttach={handleClickAttach}
          onCompleted={handleCompleted}
          onCloseDetails={closeOrderCreateSlider}
        />

        <Divider />
        <form onSubmit={submitForm}>
          <Scrollbar>
            <Stack direction={{ xs: "column", sm: "row" }}>
              {/* LEFT PANEL--------------------------------------------------- */}
              <Stack spacing={3} sx={{ px: 2.5, pt: 3, pb: 5 }}>
                <Typography variant="overline">Cliente</Typography>

                <Stack direction="column">
                  <Stack direction="row" sx={{ gap: 2 }}>
                    <Autocomplete
                      loading={isSearchingContact}
                      value={formValues.contact}
                      disablePortal
                      options={contactsList.map((c) => ({
                        label: `${c.NAME} ${c.LAST_NAME}`,
                        id: c.contactId as string,
                      }))}
                      sx={{ width: 300 }}
                      onChange={(
                        _,
                        newValue: { label: string; id: string } | null
                      ) => {
                        setFormValues({
                          ...formValues,
                          contact: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contacto"
                          onChange={(e) => {
                            handleContactSearchInput(e, false);
                          }}
                          required
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                    <Button
                      variant="text"
                      endIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => setContactCreateOpen(true)}
                    >
                      Nuevo
                    </Button>
                  </Stack>
                </Stack>

                <Stack direction="column">
                  <Stack direction="row" sx={{ gap: 2 }}>
                    <Autocomplete
                      loading={isSearchingCompany}
                      value={formValues.company}
                      disablePortal
                      options={companyList.map((c) => ({
                        label: c.TITLE,
                        id: c.companyId as string,
                      }))}
                      sx={{ width: 300 }}
                      onChange={(
                        event: any,
                        newValue: { label: string; id: string } | null
                      ) => {
                        setFormValues({
                          ...formValues,
                          company: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Empresa"
                          onChange={(e) => {
                            handleCompanySearchInput(e, false);
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                    <Button
                      variant="text"
                      endIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => setCompanyCreateOpen(true)}
                    >
                      Nuevo
                    </Button>
                  </Stack>
                </Stack>

                <Divider />

                <Typography variant="overline">Documentación</Typography>

                <Stack direction="row" sx={{ pt: 2 }}>
                  <StyledLabel sx={{ py: 0.5 }}>Documentos</StyledLabel>
                  <OrderAttachments
                    onChange={(files) =>
                      setFormValues({ ...formValues, documents: files })
                    }
                    attachments={[]}
                  />
                </Stack>
              </Stack>

              {/* RIGHT PANEL--------------------------------------------------- */}
              <Stack spacing={3} sx={{ px: 2.5, pt: 3, pb: 5, width: 430 }}>
                <Typography variant="overline">
                  Descripción y documentación requerida
                </Typography>

                <Scrollbar sx={{ height: 300 }}>
                  {formValues.products &&
                  formValues.products.length &&
                  formValues.products.reduce(
                    (prev, curr) => (curr.id ? prev + 1 : prev),
                    0
                  ) ? (
                    <>
                      {formValues.products.map((product) => {
                        const productSelected = productsList.find(
                          (p) => p.ID === product.id
                        );

                        if (productSelected) {
                          const descriptionElement = htmlParser(
                            productSelected.DESCRIPTION || ""
                          );
                          return (
                            <Stack
                              key={productSelected.ID}
                              sx={{ width: "100%", mb: 2 }}
                              spacing={2}
                            >
                              <Alert severity="warning">
                                <AlertTitle>
                                  Descripción: {productSelected.NAME}
                                </AlertTitle>
                                {descriptionElement}
                              </Alert>
                            </Stack>
                          );
                        }
                      })}
                    </>
                  ) : (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="info">
                        Una vez seleccionados los productos para esta orden, se
                        verán las descripciones y requerimientos necesarios en
                        este espacio.
                      </Alert>
                    </Stack>
                  )}
                </Scrollbar>
              </Stack>
            </Stack>

            <Divider />

            <OrderFooter
              products={formValues.products}
              onProductsChange={(products) =>
                setFormValues({ ...formValues, products })
              }
              onSubmit={onSubmit}
            />
          </Scrollbar>
        </form>
      </Drawer>
      <CreateCompanytModal
        open={companyCreateOpen}
        onClose={() => setCompanyCreateOpen(false)}
        onAddCompany={addCompany}
      />

      <CreateContactModal
        open={contactCreateOpen}
        onClose={() => setContactCreateOpen(false)}
        onAddContact={addContact}
      />
    </>
  );
}
