import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Card,
  Table,
  Button,
  TableBody,
  Container,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import CompaniesTableRow from "./CompaniesTableRow";
import CompaniesTableToolbar from "./CompaniesTableToolbar";
import CreateCompanyModal from "./CreateCompany/CreateCompanyModal";
import axiosInstance from "../../../utils/axios";
import { useBitrixContext } from "../../../api/bitrix/context/BitrixContext";
import { dbCompany } from "../../../api/parnerus/types/company";
import { useAuthContext } from "../../../auth/useAuthContext";
import axios from "../../../utils/axios";
import { useSharedContext } from "../../../shared/SharedComponentsContext";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "companyName", label: "Empresa", align: "left" },
  { id: "primaryContactId", label: "Contacto Principal", align: "left" },
  // { id: "status", label: "Status", align: "left" },
  { id: "irsStatus", label: "IRS Status", align: "left" },
  {
    id: "stateOfIncorporation",
    label: "State of Incorporation",
    align: "left",
  },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function UserListPage() {
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    setRowsPerPage,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { account } = useAuthContext();
  const { loadingTrigger } = useSharedContext();
  const { userfieldList } = useBitrixContext();
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [tableData, setTableData] = useState<dbCompany[]>([]);

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("all");

  const [openConfirm, setOpenConfirm] = useState(false);

  const [filterStatus, setFilterStatus] = useState("all");

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const STATUS_OPTIONS = userfieldList.find(
    (u) => u.FIELD_NAME === "UF_CRM_1683977873"
  )?.LIST;

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isFiltered =
    filterName !== "" || filterRole !== "all" || filterStatus !== "all";

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterStatus(event.target.value);
  };

  const handleDeleteRow = async (id: string | undefined) => {
    await axios.post("/api/company/disable", {
      companyId: id,
    });

    const deleteRow = tableData.filter((row) => row.companyId !== id);
    setSelected([]);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterRole("all");
    setFilterStatus("all");
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleAddCompany = (company: dbCompany) => {
    setTableData([company, ...tableData]);
  };

  useEffect(() => {
    setRowsPerPage(25);

    axiosInstance
      .get(`/api/company/list?accountId=${account?.accountId}`)
      .then((response) => {
        setTableData(response.data.results);
        setIsLoading(false);
      });
  }, [loadingTrigger]);

  return (
    <>
      <Helmet>
        <title> Empresas | Parnerus</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Empresas"
          links={[{ name: "CRM" }, { name: "Empresas" }]}
          action={
            <Button
              // component={RouterLink}
              // to={PATH_DASHBOARD.user.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setCreateModalOpen(true)}
            >
              Nueva empresa
            </Button>
          }
        />

        <Card>
          <CompaniesTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterStatus={filterStatus}
            optionsStatus={STATUS_OPTIONS}
            onFilterName={handleFilterName}
            onFilterStatus={handleFilterStatus}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size="medium" sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  //order={order}
                  //orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  //onSort={onSort}
                />

                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12}>
                        Cargando información...
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {dataFiltered
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <CompaniesTableRow
                          key={row.companyId}
                          row={row}
                          // selected={selected.includes(row.contactId)}
                          // onSelectRow={() => console.log("onSelectRow(row.contactId)")}
                          onDeleteRow={() => handleDeleteRow(row.companyId)}
                          // onEditRow={() => handleEditRow(row.name)}
                        />
                      ))}

                    <TableEmptyRows
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Card>
      </Container>

      <CreateCompanyModal
        open={createModalOpen}
        onClose={handleCloseCreateModal}
        onAddCompany={handleAddCompany}
      />

      {/* <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        // content={
        //   <>
        //     Are you sure want to delete <strong> {selected.length} </strong> items?
        //   </>
        // }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      /> */}
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
}: {
  inputData: dbCompany[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (company) =>
        company.TITLE.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== "all") {
    inputData = inputData.filter(
      (company) => company.UF_STATUS === filterStatus
    );
  }

  // if (filterRole !== "all") {
  //   inputData = inputData.filter((user) => user.role === filterRole);
  // }

  return inputData;
}
