import axiosInstance from "../../utils/axios";
import axios from "./helpers/axios";
import { ActivityFile, ConfigurableActivity } from "./types/activity";

export const addTimelineActivity = async (
  ownerId: number,
  ownerTypeId: number,
  responsibleId: number,
  message: string,
  files: ActivityFile[],
  sender: "client" | "parnerus",
  handleAddFiles?: (fileUris: string[]) => void
): Promise<ConfigurableActivity["layout"]> => {
  const filesBlocks: ConfigurableActivity["layout"]["body"]["blocks"] = [];
  for (const file of files) {
    filesBlocks.push({
      type: "link",
      properties: {
        text: file.name,
        action: {
          type: "redirect",
          uri: file.uri,
        },
      },
    });
  }

  const activityParams: ConfigurableActivity = {
    ownerTypeId,
    ownerId,
    fields: {
      completed: sender === "parnerus",
      responsibleId,
      // badgeCode: "smsIncoming",
    },
    layout: {
      icon: {
        code: "IM",
      },
      header: {
        title: {
          en:
            sender === "client" ? "New Order message" : "Parnerus Team message",
          la:
            sender === "client"
              ? "Nuevo mensaje desde la Orden"
              : "Nuevo mensaje del equipo de Parnerus",
        },
      },
      body: {
        logo: {
          code: "comment",
        },
        blocks: [
          {
            type: "largeText",
            properties: {
              value: message,
            },
          },
          ...filesBlocks,
        ],
      },
    },
  };

  return new Promise((resolve, reject) => {
    axiosInstance
      .post("/api/activity/insert", activityParams)
      .then(() => {
        try {
          if (sender === "parnerus") {
            axios
              .get(
                `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.get?ID=${ownerId}`
              )
              .then((dealResponse) => {
                axios
                  .post(
                    `https://master.d23g0f8v2q09zo.amplifyapp.com/api/notification/send?accountId=${dealResponse.data.result.UF_CRM_PARNERUS_ACCOUNT_ID}&message=Parnerus a dejado un mensaje en su orden&title=Nuevo mensaje de Parnerus&orderId=${ownerId}`
                  )
                  .then(() => {
                    resolve(activityParams.layout);
                  });
              });
          } else {
            axios.post(
              `${process.env.REACT_APP_BITRIX_WEBHOOK}/im.notify.system.add`,
              {
                USER_ID: 1, // responsibleId,
                MESSAGE: `Nuevo mensaje de un cliente en la siguiente orden: <a href="https://parnerus.bitrix24.com/crm/deal/details/${ownerId}/">Link a la orden</a>`,
              }
            );
            axios.post(
              `${process.env.REACT_APP_BITRIX_WEBHOOK}/im.notify.system.add`,
              {
                USER_ID: 13,
                MESSAGE: `Nuevo mensaje de un cliente en la siguiente orden: <a href="https://parnerus.bitrix24.com/crm/deal/details/${ownerId}/">Link a la orden</a>`,
              }
            );
            axios.post(
              `${process.env.REACT_APP_BITRIX_WEBHOOK}/im.notify.system.add`,
              {
                USER_ID: 15,
                MESSAGE: `Nuevo mensaje de un cliente en la siguiente orden: <a href="https://parnerus.bitrix24.com/crm/deal/details/${ownerId}/">Link a la orden</a>`,
              }
            );
            if (handleAddFiles) {
              handleAddFiles(files.map((f) => f.uri));
            }
            resolve(activityParams.layout);
          }
        } catch (err) {
          console.log("Activiy adding error", err);
        }
      })
      .catch((error) => reject(error.message));
  });
};

export const getDealActivities = (
  dealId: string
): Promise<{ data: ConfigurableActivity["layout"]; createdDate: Date }[]> =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.activity.list?FILTER[PROVIDER_ID]=CONFIGURABLE_REST_APP&FILTER[OWNER_ID]=${dealId}&FILTER[OWNER_TYPE_ID]=2&order[ID]=DESC`
      )
      .then((response) =>
        resolve(
          response.data.result.map(
            (activity: { PROVIDER_DATA: string; CREATED: string }) => ({
              data: { ...JSON.parse(activity.PROVIDER_DATA) },
              createdDate: new Date(activity.CREATED),
            })
          )
        )
      )
      .catch((error) => reject(error));
  });
