/* eslint-disable indent */
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "../../../../utils/axios";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useBitrixContext } from "../../../../api/bitrix/context/BitrixContext";
import { useSharedContext } from "../../../../shared/SharedComponentsContext";
import { useAuthContext } from "../../../../auth/useAuthContext";
import axiosInstance from "../../../../utils/axios";
import { dbCompany } from "../../../../api/parnerus/types/company";

interface Props extends DialogProps {
  onClose: VoidFunction;
}

interface FormProps {
  contactId: string | undefined;
  NAME: string | undefined;
  LAST_NAME: string | undefined;
  PHONE: string | undefined;
  EMAIL: string | undefined;
  UF_STATUS: string | undefined;
  UF_IRS_STATUS: string | undefined;
  UF_OCUPATION: string[] | undefined;
  UF_INTEREST: string[] | undefined;
  UF_TYPE_OF_SERVICE: string[] | undefined;
  BIRTHDATE: string | undefined;
  UF_ADDRESS: string | undefined;
  SOURCE_ID: string | undefined;
  UF_REFERRAL: string | undefined;
  COMMENTS: string | undefined;
  COMPANIES:
    | {
        id: string;
        label: string;
      }[]
    | undefined;
}

const formDefaults: Partial<FormProps> = {
  NAME: "",
  LAST_NAME: "",
  PHONE: "",
  EMAIL: "",
  UF_STATUS: "",
  UF_IRS_STATUS: "",
  UF_OCUPATION: [],
  UF_INTEREST: [],
  UF_TYPE_OF_SERVICE: [],
  BIRTHDATE: "",
  UF_ADDRESS: "",
  SOURCE_ID: "",
  UF_REFERRAL: "",
  COMMENTS: "",
  COMPANIES: [],
};

export default function EditContactModal({ onClose, open }: Props) {
  const { account } = useAuthContext();
  const { contact, setContact, triggerLoading } = useSharedContext();
  const { userfieldList, listFields } = useBitrixContext();
  const [formValues, setFormValues] = useState(formDefaults);
  const [onSubmit, setOnSubmit] = useState(false);

  // Company filtering
  const [companyList, setCompanyList] = useState<dbCompany[]>();
  const [companySearchingTimer, setCompanySearchingTimer] =
    useState<NodeJS.Timeout | null>(null);
  const [isSearchingCompany, setIsSearchingCompany] = useState(false);

  const handleCompanySearchInput = (
    event: { target: { value: string } },
    isFirstSearch: boolean
  ) => {
    setIsSearchingCompany(true);
    const searchingValue = event.target.value.toLowerCase();
    if (companySearchingTimer) clearTimeout(companySearchingTimer);

    const newContactTimer = setTimeout(async () => {
      if (isFirstSearch || searchingValue.length > 2) {
        const searchingResults = await axiosInstance.get(
          `/api/company/list-by-title?TITLE=${searchingValue}&accountId=${account?.accountId}`
        );
        setCompanyList(searchingResults.data.results);
        setIsSearchingCompany(false);
      } else {
        setCompanyList([]);
        setIsSearchingCompany(false);
      }
    }, 1500);

    setCompanySearchingTimer(newContactTimer);
  };

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmit(true);
    const contactUpdated = (
      await axios.post("/api/contact/update", {
        ...formValues,
        COMPANIES: formValues.COMPANIES?.map((c) => c.id),
      })
    ).data.results.Attributes;

    setContact(contactUpdated);
    setFormValues(formDefaults);
    onClose();
    setOnSubmit(false);
    triggerLoading();
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (contact && companyList && open) {
      setFormValues({
        contactId: contact.contactId,
        NAME: contact.NAME || "",
        LAST_NAME: contact.LAST_NAME || "",
        PHONE: contact.PHONE || "",
        EMAIL: contact.EMAIL || "",
        UF_STATUS: contact.UF_STATUS || "",
        UF_IRS_STATUS: contact.UF_IRS_STATUS || "",
        UF_OCUPATION: contact.UF_OCUPATION || [],
        UF_INTEREST: contact.UF_INTEREST || [],
        UF_TYPE_OF_SERVICE: contact.UF_TYPE_OF_SERVICE || [],
        BIRTHDATE: contact.BIRTHDATE || "",
        UF_ADDRESS: contact.UF_ADDRESS || "",
        SOURCE_ID: contact.SOURCE_ID || "",
        UF_REFERRAL: contact.UF_REFERRAL || "",
        COMMENTS: contact.COMMENTS || "",
        COMPANIES: [],
      });
    }
  }, [contact, companyList, open]);

  useEffect(() => {
    if (formValues.contactId && companyList && companyList.length > 0) {
      setFormValues({
        ...formValues,
        COMPANIES:
          contact?.COMPANIES?.map((companyId) => {
            const company = companyList.find((c) => c.companyId === companyId);
            return {
              label: company?.TITLE as string,
              id: company?.companyId as string,
            };
          }) || [],
      });
    }
  }, [formValues.contactId, companyList]);

  useEffect(() => {
    // if (open) {
    handleCompanySearchInput({ target: { value: "" } }, true);
    // }
  }, []);

  if (!contact) {
    return <></>;
  }

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle> Editar contacto </DialogTitle>

        <form onSubmit={submitForm}>
          <DialogContent sx={{ overflow: "unset" }}>
            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Información general
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="NAME"
                value={formValues.NAME}
                onChange={handleInputChange}
                fullWidth
                label="Nombre"
                required
              />
              <TextField
                name="LAST_NAME"
                value={formValues.LAST_NAME}
                onChange={handleInputChange}
                fullWidth
                label="Apellido"
                required
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="PHONE"
                value={formValues.PHONE}
                onChange={handleInputChange}
                fullWidth
                label="Teléfono"
                type="phone"
              />
              <TextField
                name="EMAIL"
                value={formValues.EMAIL}
                onChange={handleInputChange}
                fullWidth
                label="Email"
                type="email"
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Status"
                fullWidth
                name="UF_STATUS"
                value={formValues.UF_STATUS}
                onChange={handleInputChange}
                required
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683974997")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                select
                label="IRS Status"
                fullWidth
                name="UF_IRS_STATUS"
                value={formValues.UF_IRS_STATUS}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683975126")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <Autocomplete
                fullWidth
                multiple
                loading={isSearchingCompany}
                value={formValues.COMPANIES}
                disablePortal
                options={
                  companyList?.map((c) => ({
                    label: c.TITLE,
                    id: c.companyId as string,
                  })) || []
                }
                onChange={(event, newValue) => {
                  setFormValues({
                    ...formValues,
                    COMPANIES: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Empresas"
                    onChange={(e) => {
                      handleCompanySearchInput(e, false);
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Stack>

            {/* <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Compañía
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField fullWidth label="Nombre de la compañía" />
            </Stack> */}

            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Detalles
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Ocupation"
                fullWidth
                name="UF_OCUPATION"
                value={formValues.UF_OCUPATION}
                onChange={handleInputChange}
                SelectProps={{
                  multiple: true,
                  renderValue: (value: unknown) =>
                    value
                      ? (value as string[])
                          .map(
                            (id) =>
                              userfieldList
                                .find(
                                  (u) => u.FIELD_NAME === "UF_CRM_1684065883"
                                )
                                ?.LIST.find((v) => v.ID == id)?.VALUE
                          )
                          .join(", ")
                      : "",
                }}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684065883")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      <Checkbox
                        checked={
                          formValues.UF_OCUPATION &&
                          formValues.UF_OCUPATION.indexOf(value.ID) > -1
                        }
                      />
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>

              <TextField
                select
                label="Interest"
                fullWidth
                name="UF_INTEREST"
                value={formValues.UF_INTEREST}
                onChange={handleInputChange}
                SelectProps={{
                  multiple: true,
                  renderValue: (value: unknown) =>
                    value
                      ? (value as string[])
                          .map(
                            (id) =>
                              userfieldList
                                .find(
                                  (u) => u.FIELD_NAME === "UF_CRM_1684066139"
                                )
                                ?.LIST.find((v) => v.ID == id)?.VALUE
                          )
                          .join(", ")
                      : "",
                }}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684066139")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      <Checkbox
                        checked={
                          formValues.UF_INTEREST &&
                          formValues.UF_INTEREST.indexOf(value.ID) > -1
                        }
                      />
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Type of service"
                fullWidth
                name="UF_TYPE_OF_SERVICE"
                value={formValues.UF_TYPE_OF_SERVICE}
                onChange={(event) =>
                  handleInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: [event.target.value],
                    },
                  })
                }
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684080063")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>

              <DesktopDatePicker
                label="Fecha de nacimiento"
                value={
                  formValues.BIRTHDATE ? new Date(formValues.BIRTHDATE) : null
                }
                defaultCalendarMonth={new Date("2000-01-01")}
                onChange={(newDate) => {
                  if (
                    newDate instanceof Date &&
                    !isNaN(newDate as unknown as number)
                  ) {
                    setFormValues({
                      ...formValues,
                      BIRTHDATE: newDate.toISOString(),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} margin="normal" />
                )}
              />
            </Stack>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                fullWidth
                label="Dirección"
                name="UF_ADDRESS"
                value={formValues.UF_ADDRESS}
                onChange={handleInputChange}
              />

              <TextField
                select
                label="Origen"
                fullWidth
                name="SOURCE_ID"
                value={formValues.SOURCE_ID}
                onChange={handleInputChange}
              >
                {listFields
                  .find((u) => u.FIELD_NAME === "SOURCE")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.STATUS_ID} value={value.STATUS_ID}>
                      {value.NAME}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            {formValues.SOURCE_ID && formValues.SOURCE_ID === "2" && (
              <Stack
                spacing={3}
                marginBottom={3}
                direction={{ xs: "column", sm: "row" }}
              >
                <TextField
                  fullWidth
                  label="Referral"
                  name="UF_REFERRAL"
                  value={formValues.UF_REFERRAL}
                  onChange={handleInputChange}
                />
              </Stack>
            )}

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                fullWidth
                label="Comentario"
                multiline
                rows={4}
                name="COMMENTS"
                value={formValues.COMMENTS}
                onChange={handleInputChange}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              color="inherit"
              variant="outlined"
              onClick={onClose}
              disabled={onSubmit}
            >
              Cancelar
            </Button>

            <Button variant="contained" type="submit" disabled={onSubmit}>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
