/* eslint-disable indent */
import { ChangeEvent, useEffect, useState } from "react";
// @mui
import {
  Stack,
  Button,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Iconify from "../../components/iconify";
import ConfirmDialog from "../../components/confirm-dialog";
import { useSharedContext } from "../SharedComponentsContext";
import Label from "../../components/label";
import { calculateDealStageProgress } from "../../api/bitrix/status";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { Status } from "../../api/bitrix/types/status";
import { BitrixFile } from "../../api/bitrix/types/deal";
import { fileToBase64 } from "../../api/parnerus/helpers/files";
import axiosInstance from "../../utils/axios";
import { useAuthContext } from "../../auth/useAuthContext";
import { updateDealDocuments } from "../../api/bitrix/deal";

// ----------------------------------------------------------------------

type Props = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  taskName: string;
  liked: boolean;
  completed: boolean;
  onLike: VoidFunction;
  onAttach: VoidFunction;
  onCompleted: VoidFunction;
  onCloseDetails: VoidFunction;
  orderFiles: string[];
  setOrderFiles: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function OrderDetailsToolbar({
  fileInputRef,
  taskName,
  liked,
  completed,
  onLike,
  onAttach,
  onCompleted,
  onCloseDetails,
  orderFiles,
  setOrderFiles,
}: Props) {
  const { account } = useAuthContext();
  const { deal } = useSharedContext();
  const { listFields } = useBitrixContext();
  const [stageProgress, setStageProgress] = useState<number>(0);
  const isDesktop = useResponsive("up", "sm");

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (deal && e.target.files) {
      const files = Array.from(e.target.files);
      console.log("files:", files);

      const dealFiles: BitrixFile[] = [];
      for (const document of files) {
        const fileData = (await fileToBase64(document as File)) as string;
        if (fileData) {
          dealFiles.push({
            fileData: [(document as File).name, fileData.split(";base64,")[1]],
          });
        }
      }

      await updateDealDocuments(deal.ID, dealFiles);
      const fileUrls = (
        await axiosInstance.post("/api/order/upload-files", {
          files: dealFiles,
          bitrixId: deal?.ID,
          accountId: account?.accountId,
        })
      ).data.results;

      setOrderFiles([...orderFiles, ...fileUrls]);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    if (deal?.STAGE) {
      calculateDealStageProgress(
        deal.STAGE.STATUS_ID,
        listFields.find((f) => f.FIELD_NAME === "DEAL_STAGE")?.LIST as Status[]
      ).then(setStageProgress);
    }
  }, []);

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        {!isDesktop && (
          <Tooltip title="Back">
            <IconButton onClick={onCloseDetails} sx={{ mr: 1 }}>
              <Iconify icon="eva:arrow-ios-back-fill" />
            </IconButton>
          </Tooltip>
        )}

        {deal?.INVOICE_STATUS?.NAME ? (
          <Label
            variant="soft"
            color={
              deal.INVOICE_STATUS.NAME === "Paid"
                ? "success"
                : deal.INVOICE_STATUS.NAME === "Do not pay"
                ? "error"
                : deal.INVOICE_STATUS.NAME === "Past Due"
                ? "warning"
                : "primary"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {deal.INVOICE_STATUS.NAME}
          </Label>
        ) : (
          "-"
        )}

        {deal?.STAGE && (
          <Stack spacing={1} width={100} sx={{ mx: 2 }}>
            <Stack direction="row" alignItems="center">
              <Typography variant="caption" sx={{ flexGrow: 1 }}>
                {deal.STAGE.NAME}
              </Typography>
            </Stack>

            <LinearProgress
              variant="determinate"
              value={stageProgress}
              color="primary"
            />
          </Stack>
        )}

        {/* <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          flexGrow={1}
        >
          <Tooltip title="Subir archivos adicionales">
            <IconButton size="small" onClick={onAttach}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>
          </Tooltip>
        </Stack> */}
      </Stack>

      <input
        onChange={handleFileUpload}
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
      />
    </>
  );
}
