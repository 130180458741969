// @mui
import { Stack } from "@mui/material";
// components
import { CustomFile, MultiFilePreview } from "../../components/upload";

// ----------------------------------------------------------------------

type Props = {
  attachments: string[];
  iconSize?: number;
  onRemove?: ((file: CustomFile | string) => void) | undefined;
};

export default function OrderAttachments({
  attachments,
  iconSize = 64,
  onRemove,
}: Props) {
  return (
    <Stack direction="row" flexWrap="wrap">
      <MultiFilePreview
        onRemove={onRemove}
        thumbnail
        files={attachments}
        sx={{ width: iconSize, height: iconSize }}
      />
    </Stack>
  );
}
